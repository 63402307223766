<template>
  <section id="intents-filter">
    <label :for="`condition-tags-${key}`" v-if="label">
      {{ lang.metrics.filters.intent.label[languageSelected] }}
    </label>
    <multiselect
      :id="`condition-tags-${key}`"
      :disabled="!serviceId"
      :loading="loadingIntents"
      v-model="intentSelected"
      :showLabels="false"
      :placeholder="
        multiple
          ? lang.metrics.filters.intent.placeholder.multiple[languageSelected]
          : lang.metrics.filters.intent.placeholder.single[languageSelected]
      "
      label="name"
      track-by="id"
      :options="intents"
      :multiple="multiple"
      :taggable="true"
      tagPlaceholder=""
      @input="change"
    >
      <span slot="noOptions">
        {{ lang.metrics.filters.noOptions[languageSelected] }}
      </span>
    </multiselect>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import BotMakerService from '../../../../../services/botMaker.service'

export default {
  name: 'IntentFilter',
  props: {
    serviceId: {
      type: String
    },
    versionId: String,
    label: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Array,
      default: null
    },
    multiple: {
      type: Boolean,
      default: true
    },
    defaultOptions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      key: new Date().toISOString(),
      intents: [],
      intentSelected: [],
      loadingIntents: false
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['intentionsMap'])
  },
  methods: {
    ...mapActions('botMaker', ['GET_INTENTIONS_BY_VERSION']),
    async loadIntents() {
      if (this.serviceId) {
        this.loadingIntents = true
        try {
          let versionId = null
          if (this.versionId) {
            versionId = this.versionId
          } else {
            const versionData = await BotMakerService.getActiveVersion(
              this.serviceId
            )
            versionId =
              versionData && versionData.data[0] && versionData.data[0]._id
          }

          if (!versionId) {
            this.intents = []
            return
          }

          let response
          if (this.intentionsMap[versionId]) {
            response = this.intentionsMap[versionId]
          } else {
            response = await this.GET_INTENTIONS_BY_VERSION(versionId)
          }

          if (response.length) {
            this.intents = this.defaultOptions
              ? [
                  {
                    id: 0,
                    name: this.lang.metrics.filters.intent.allIntents[
                      this.languageSelected
                    ]
                  },
                  {
                    id: 1,
                    name: this.lang.metrics.filters.intent.notDetected[
                      this.languageSelected
                    ]
                  }
                ]
              : []
            response.forEach(intent => {
              this.intents.push({
                id: intent._id,
                name: `#${intent.name}`
              })
            })

            if (this.selected) {
              this.intentSelected = [
                {
                  id: this.selected[0]._id,
                  name: `#${this.selected[0].intent}`
                }
              ]
            }
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingIntents = false
        }
      }
    },
    change() {
      const allIntentsLabel = this.lang.metrics.filters.intent.allIntents[
        this.languageSelected
      ]
      const allSelected = this.intentSelected
        ? this.multiple
          ? this.intentSelected.some(i => i.name === allIntentsLabel)
          : this.intentSelected.name === allIntentsLabel
        : false

      if (allSelected) {
        this.intentSelected = this.intentSelected.filter(
          i => i.name === allIntentsLabel
        )
      }

      this.$emit('change', {
        type: 'intent',
        value: this.intentSelected
      })
    },
    reset() {
      this.intentSelected = []
      this.change()
      this.loadIntents()
    }
  },
  watch: {
    serviceId() {
      this.reset()
    },
    versionId() {
      this.reset()
    }
  },
  mounted() {
    this.loadIntents()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
